<template>
	<div class="category-wrapper" v-background="backgroundOverlay">
		<base-container>
			<div class="category">
				<div class="category-list" v-for="(key, value) in categoryData" :key="value">
					<base-font v-if="lang[value]" variant="small-heading" color="white">{{lang[value]}}:</base-font>
					<ul class="category-items">
						<base-category-item  v-for="(categoryElement, index) in key" :key="index"
											:category="categoryElement" :activeCategory="activeCategories"
											@categorySelected="handler(value, $event); setActiveCategories($event)" />
					</ul>
				</div>
			</div>
		</base-container>
	</div>
</template>

<script>
import BaseCategoryItem from '~/website/front/components/molecules/BaseCategory/BaseCategoryItem'

export default {
	props: {
		handler: Function,
		categoryData: [Array, Object],
		selectedTags: Object,
		singleCategory: Boolean
	},
	model: {
		prop: 'selectedTags',
		event: 'tagSelected'
	},
	components: {
		BaseCategoryItem
	},
	data: () => ({
		activeCategories: []
	}),
	computed: {
		readQuery () {
			return this.$app.page.query.category
		},
		lang () {
			return Object.assign(this.$app.translator.get('products'), this.$app.translator.get('realizations'))
		},
		backgroundOverlay () {
			const path = '/homepage/category-image.jpg'
			return {
				src: path,
				breakpoints: {
					base: { width: 720, height: 650 },
					md: { width: 900, height: 650 },
					lg: { width: 1024, height: 451 },
					xl: { width: 1440, height: 800 },
					xxl: { width: 1920, height: 515 }
				}
			}
		}
	},
	methods: {
		chooseIcon (category) {
			return {
				powerSaving: 'eco',
				securityAndHealth: 'safety',
				design: 'design_palette',
				standard: 'standard',
				premium: 'premium',
				exclusive: 'exclusive'
			}[category]
		},
		setActiveCategories (category) {
			if (this.singleCategory) {
				if (!this.activeCategories.includes(category)) {
					this.activeCategories = [category]
				} else {
					this.activeCategories = []
				}
			} else {
				if (!this.activeCategories.includes(category)) {
					this.activeCategories.push(category)
				} else {
					const categoryIndex = this.activeCategories.indexOf(category)
					this.activeCategories.splice(categoryIndex, 1)
				}
			}
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
		await this.$app.translator.prefetch('realizations')
	},
	mounted () {
		if (this.categoryData.categories.includes(this.readQuery)) {
			this.handler('categories', this.readQuery)
			this.setActiveCategories(this.readQuery)
		}
	}
}
</script>

<style lang="scss" scoped>
    .category-wrapper {
		padding: 2.5rem 0;
		//background: $secondary;
		position: relative;
		width: 100%;
		display: flex;
        &::before {
            content: '';
            position: absolute;
            top:0;
            left: 0;
            width:100%;
            height: 100%;
            background: $gray-600;
            opacity: .7;
        }
    }
	.category {
		display: flex;
		flex-direction: column;
		gap: 20px;
        flex-wrap: wrap;
		@include media-breakpoint-up(xl) {
			flex-direction: row;
			gap: 40px;
		}
        .category-list {
            z-index: 2;
        }
	}
    .category-items {
        display: flex;
		gap: 10px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }
</style>
