<template>
	<base-font tag="li" size="xs" class="category-item">
		<button class="category-button" @click="handleClick" :class="{active: isActive}">
			<base-svg :class="category" :name="chooseIcon(category)"/>
            <base-font tag="span" class="content-wrapper">{{lang[category]}}</base-font>
		</button>
	</base-font>
</template>
<script>
export default {
	props: {
		category: String,
		activeCategory: Array
	},
	computed: {
		lang () {
			return this.$app.translator.get('products')
		},
		isActive () {
			return this.activeCategory.includes(this.category)
		}
	},
	methods: {
		chooseIcon (category) {
			return {
				powerSaving: 'eco',
				securityAndHealth: 'safety',
				design: 'design_palette',
				standard: 'standard',
				premium: 'premium',
				exclusive: 'exclusive',
				private: 'private',
				developer: 'deweloper',
				public: 'public',
				factory: 'factory'
			}[category]
		},
		handleClick () {
			this.$emit('categorySelected', this.category)
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>
<style lang="scss" scoped>
    .category-button {
        font-size: 1.6rem;
        padding: 1rem 2.5rem;
        font-weight: normal;
		border-radius: 2px;
		background: $white;
		outline: none;
		text-transform: uppercase;
		border: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 10px;
		width: 100%;
		justify-content: center;
        position: relative;
        transition: 0.3s ease-in;
        height: 100%;
        text-align: left;
        @include media-breakpoint-up(xl) {
            padding: 1.5rem 2rem;
			width: auto;
            height: 100%;
        }
        @include media-breakpoint-up(xxl) {
            padding: 1.5rem 4rem;
        }
		.svg-icon {
			width: 15px;
			height: 15px;
            min-width: 15px;

			&.standard ::v-deep path {
				fill: $gray-400;
			}
			&.exclusive ::v-deep path {
				fill: $yellow-500;
			}
            &.premium ::v-deep path {
                fill: $secondary-300;
            }
            &.developer ::v-deep path {
                fill: $secondary-300;
            }
            &.public ::v-deep path {
                fill: $yellow-500;
            }
            &.factory ::v-deep path {
                fill: $gray-400;
            }
		}
        &.active {
            color: $white;
            ::v-deep .content-wrapper {
                color: $white;
                transform: translateY(-3px);
            }
            background: $secondary;
            box-shadow: 0 3px 20px rgba($black, .2);

            .svg-icon {
                transform: translateY(-3px);
               ::v-deep path {
                    fill: $white;
                }
            }
            &::after {
                transform: scaleY(1.8);
            }
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            transition: 0.3s ease-in;
            transform-origin: bottom;
            background: $secondary;
        }
    &:hover {
        ::v-deep .content-wrapper {
            transform: translateY(-3px)
        }
        &::after {
            transform: scaleY(1.8);
        }
        .svg-icon {
            transform: translateY(-3px);
        }
    }
    ::v-deep .content-wrapper {
        transition: 0.3s ease-in;
        letter-spacing: normal;
        @include media-breakpoint-up(md) {
            line-height: 1.8rem;
        }
        @include media-breakpoint-up(lg) {
            line-height: inherit;
        }
    }
    }
</style>
