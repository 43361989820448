<template>
	<section class="product-list">
		<div class="filters">
			<base-category :categoryData="productCategories" :handler="handleInput" v-model="selectedTags" />
		</div>
		<base-container>
			<div class="product-list-content">
				<grid-template class="products-grid" cols="4">
					<skeleton-loader v-for="product in filteredProducts" :key="product.id"  :isVisible="loading">
						<product-card :key="product.id" :product="product" />
					</skeleton-loader>
				</grid-template>
				<base-font v-if="!filteredProducts.length" variant="medium-heading" weight="medium">{{langs.empty}}</base-font>
			</div>
		</base-container>
	</section>
</template>

<script>
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader'
import BaseCategory from '~/website/front/components/molecules/BaseCategory/BaseCategory'
import ProductCard from '~/website/front/components/molecules/cards/ProductCard/ProductCard'
import GridTemplate from '~/website/front/templates/GridTemplate'
import { ProductCategories } from 'utils/enums/ProductCategories'
import { isPageFromNull } from 'utils/ssrCheck'

export default {
	props: {
		prefix: String,
		value: Object
	},
	components: {
		SkeletonLoader,
		BaseCategory,
		ProductCard,
		GridTemplate
	},
	computed: {
		productCategories () {
			return ProductCategories
		},
		productsType () {
			const section = this.$app.page.value.content.find(section => section.alias === 'product_list_type')
			return section ? section.value.type : null
		},
		langs () {
			return this.$app.translator.get('products')
		}
	},
	data: () => ({
		selectedTags: {},
		loading: true,
		products: [],
		filteredProducts: []
	}),
	methods: {
		filterProducts () {
			if (!Object.keys(this.selectedTags).length) this.filteredProducts = this.products

			const filteredProducts = this.products.filter(product => {
				let areTraitsInProduct = true
				let areCategoriesInProduct = true

				if (this.selectedTags.traits) {
					areTraitsInProduct = this.selectedTags.traits.map(trait => {
						return product.tags.traits.find(t => t === trait)
					}).every(t => !!t)
				}
				if (this.selectedTags.categories) {
					areCategoriesInProduct = this.selectedTags.categories.map(category => {
						return product.tags.categories.find(c => c === category)
					}).every(c => !!c)
				}
				return areTraitsInProduct && areCategoriesInProduct
			})
			this.filteredProducts = filteredProducts
		},
		async handleInput (tag, element) {
			this.loading = true
			setTimeout(() => {
				this.loading = false
			}, 500)
			if (!this.selectedTags[tag]) {
				this.selectedTags[tag] = [element]
			} else {
				if (!this.selectedTags[tag].includes(element)) {
					this.selectedTags[tag].push(element)
				} else {
					const elementIndex = this.selectedTags[tag].indexOf(element)
					this.selectedTags[tag].splice(elementIndex, 1)
				}
			}
			this.filterProducts()
		},
		async fetchProducts () {
			const Product = this.$app.getEntity('product')
			const fetchOptions = {
				order: {
					order: 'ASC'
				},
				take: 100
			}
			if (this.productsType) {
				fetchOptions.where = {
					type: this.productsType
				}
			}
			const { products } = await Product.fetch(fetchOptions)
			this.products = products
			setTimeout(() => {
				this.loading = false
			}, 1500)
		},
		recreateDataFromComponentsData () {
			const Product = this.$app.getEntity('product')
			this.products = this.$app.page.componentsData.product_manager.products.map(product => Product.create(product))
			setTimeout(() => {
				this.loading = false
			}, 500)
		}
	},
	async prefetch () {
		if (process.server) {
			await this.fetchProducts()
			this.$app.page.componentsData.product_manager = {}
			this.$app.page.componentsData.product_manager.products = this.products
		}
	},
	async mounted () {
		if (isPageFromNull(this.$app)) this.recreateDataFromComponentsData()
		else {
			await this.fetchProducts()
		}

		this.filterProducts()
	}
}
</script>
<style lang="scss" scoped>
.products-wrapper {
	min-height: 405px;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 2rem;
	@include media-breakpoint-up(md) {
		grid-template-columns: 1fr 1fr;
	}
	@include media-breakpoint-up(lg) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@include media-breakpoint-up(xl) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

.product-list-navigation {
	display: flex;
	justify-content: flex-end;
}
.product-list {
	padding-bottom: 4rem;
    ::v-deep .products-grid {
       grid-auto-rows: 410px;
    }
}

.category {

	&-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 5rem;

	}
	&-item {
		background: $white;
		cursor: pointer;
		padding: 1rem 2rem;
		margin-top: 1rem;
		margin-right: 2rem;
		transition: 0.3s all;
		user-select: none;

		@include media-breakpoint-up(xl){
			padding: 1.5rem 3rem;
			margin-right: 3rem;
			margin-top: 2rem;
		}

		&:hover {
			background: $gray;
		}
		&-active {
			background: $success;
			::v-deep .category-item-name {
				color: $white !important;
			}
			&:hover {
				background: $success;
			}
		}
	}
}
</style>
